import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import querystring from "querystring";

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ location }) => {
  // const data = useStaticQuery(graphql`
  //     query {
  //         placeholderImage: file(relativePath: { eq: "GroovyWebFlyer.png" }) {
  //             childImageSharp {
  //                 fluid(maxWidth: 1000) {
  //                     ...GatsbyImageSharpFluid
  //                 }
  //             }
  //         }
  //     }
  // `)
  // const { aspectRatio } = data.placeholderImage.childImageSharp.fluid;
  //
  // const [buyCTA, setBuyCTA] = useState('BUY TIX');
  // const [tixLink, setTixLink] = useState('https://www.residentadvisor.net/events/1362527');

  // useEffect(() => {
  //   if (location.search) {
  //     const searchString = location.search.charAt(0) === '?' ? location.search.substr(1) : location.search;
  //     const query = querystring.parse(searchString);
  //     if (query && query.code) {
  //       setBuyCTA('DISCOUNT TIX');
  //       setTixLink(`https://www.residentadvisor.net/events/1362527?p=${query.code}`);
  //       return;
  //     }
  //   }
  //
  //   setBuyCTA('BUY TIX');
  //   setTixLink('https://www.residentadvisor.net/events/1362527');
  // }, [location.search]);

  return (
    <Layout>
      <SEO title="Home"/>
      {/*<div className="flyer" style={{ maxWidth: `${aspectRatio * 70}vh`}}>*/}
      {/*  <Img fluid={data.placeholderImage.childImageSharp.fluid} style={{ maxWidth: `${aspectRatio * 70}vh`}} />*/}
      {/*</div>*/}
      {/*<div className="buttons">*/}
      {/*  <a className="button buy-tix" href={tixLink} target="_blank" rel="noopener noreferrer">*/}
      {/*    {buyCTA}*/}
      {/*  </a>*/}
      {/*  <Link className="button rsvp" to="/rsvp">*/}
      {/*    RSVP*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </Layout>
  )
}

export default IndexPage
